import Vue from 'vue';
import Vuetify, {
  VList,
  VListItem,
  VListItemAvatar,
  VListItemContent,
  VListItemAction,
  VListItemTitle,
  VListItemSubtitle,
  VIcon,
  VLayout
} from 'vuetify/lib';
import { Touch, Scroll, Intersect } from 'vuetify/lib/directives';
/**  Custom Icons */
import BubbleLogoIcon from '../icons/BubbleLogoIcon.vue';
import BubblOutlineIcon from '../icons/bubbl-outline-icon.vue';
import BabyFaceIcon from '../icons/baby-face-icon.vue';
import BabyAngelIcon from '../icons/baby-angel-icon.vue';
import AlienIcon from '../icons/alien-icon.vue';
import SmirkingCat from '../icons/smirking-cat.vue';
import '@/default-font.sass';

Vue.use(Vuetify, {
  directives: {
    Touch,
    Scroll,
    Intersect
  },
  components: {
    VList,
    VIcon,
    VLayout,
    VListItem,
    VListItemAvatar,
    VListItemContent,
    VListItemAction,
    VListItemTitle,
    VListItemSubtitle
  }
});

export default new Vuetify({
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#795FDC'
      }
    }
  },
  icons: {
    values: {
      bubbl: {
        component: BubbleLogoIcon
      },
      'bubbl-outline': {
        component: BubblOutlineIcon
      },
      'baby-face': {
        component: BabyFaceIcon
      },
      'baby-angel': {
        component: BabyAngelIcon
      },
      alien: {
        component: AlienIcon
      },
      'smirking-cat': {
        component: SmirkingCat
      }
    }
  }
});

// Try this themes primary color: #8937f3
