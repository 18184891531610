<template>
  <v-app>
    <!-- <v-app-bar app hide-on-scroll class="elevation-0" dense height="30px" light>
      <v-toolbar-title class="mx-auto d-flex text-center color-purple">
        Not found
      </v-toolbar-title>
    </v-app-bar> -->
    <v-main>
      <div
        class="d-flex text-center primary lighten-3 justify-center align-center container"
        style="height: 100%"
      >
        <div class="text-center">
          <p style="font-size: 50px">
            🔍
          </p>
          ye! can't find that {{ missingItem || 'page' }}

          <br />

          <router-link to="/">Back home</router-link>
        </div>
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'not-found-page',
  computed: {
    missingItem() {
      return this.$route.query['lost'];
    }
  },
  head: {
    title: '404 Page not found :/ - bubbl'
  },
  mounted() {
    if (this.$gtag) {
      this.$gtag.event('404_page', { event_label: 'Google' });
    }
  }
};
</script>

<style></style>
