/* eslint-disable no-console */

import { register } from 'register-service-worker';

if ('serviceWorker' in navigator) {
  // navigator.serviceWorker.getRegistrations().then(function(registrations) {
  //   console.log('Unregistering...');
  //   for (let registration of registrations) {
  //     console.log('Unregistering fr!');
  //     registration.unregister();

  //     setTimeout(function() {
  //       console.log('reloading');
  //       window.location.reload(); // because without redirecting, first time on page load: still service worker will be available
  //     }, 3000);
  //   }
  // });

  // if ('caches' in window) {
  //   caches.keys().then(function(keyList) {
  //     return Promise.all(
  //       keyList.map(function(key) {
  //         return caches.delete(key);
  //       })
  //     );
  //   });
  // }

  // navigator.serviceWorker.getRegistrations().then(function(registrations) {
  //   for (let registration of registrations) {
  //     registration
  //       .unregister()
  //       .then(function() {
  //         return self.clients.matchAll();
  //       })
  //       .then(function(clients) {
  //         clients.forEach(client => {
  //           if (client.url && 'navigate' in client) {
  //             client.navigate(client.url);
  //           }
  //         });
  //       });
  //   }
  // });

  // if service worker is in the navigator sef

  if (process.env.NODE_ENV === 'production') {
    register(`${process.env.BASE_URL}service-worker.js`, {
      ready() {
        console.log(
          'App is being served from cache by a service worker.\n' +
            'For more details, visit https://goo.gl/AFskqB'
        );
      },
      registered(registration) {
        setInterval(() => {
          registration.update();
        }, 1000 * 60 * 60); // e.g. hourly checks
      },
      cached() {
        console.log('Content has been cached for offline use.');
      },
      updatefound() {
        console.log('New content is downloading.');
      },
      updated(registration) {
        console.log('New content is available; please refresh.');
        // send reload event to app-view.vue so the reload toast shows :)
        document.dispatchEvent(
          new CustomEvent('swUpdated', { detail: registration })
        );
        // let confirmationResult = confirm(
        //   'New content found! Do you want to reload the app?'
        // );
        // if (confirmationResult)
        //   registration.waiting.postMessage({ action: 'skipWaiting' });
      },
      offline() {
        console.log(
          'No internet connection found. App is running in offline mode.'
        );
      },
      error(error) {
        console.error('Error during service worker registration:', error);
      }
    });
  }

  // var refreshing;
  // navigator.serviceWorker.addEventListener('controllerchange', () => {
  //   if (refreshing) return;
  //   window.location.reload();
  //   refreshing = true;
  // });
}
