import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
// import VueSocketIO from 'vue-socket.io-extended';
// import io from 'socket.io-client';
import VueGtag from 'vue-gtag';

if (process.env.NODE_ENV === 'production') {
  console.log('Activated Google Analytics');

  Vue.use(VueGtag, {
    config: { id: 'UA-165866085-1' }
  });
}

// Socketssss :p
// const socket = io('https://play.mybubbl.me', { autoConnect: true });
// Vue.use(VueSocketIO, socket);

Vue.filter('truncate', function(text, stop, clamp) {
  return (
    text.slice(0, stop) +
    (stop < text.length ? clamp || '... \n 𝒓𝒆𝒂𝒅 𝒕𝒉𝒆 𝒓𝒆𝒔𝒕 👉' : '')
  );
});

Vue.config.productionTip = false;

export const backer = {
  data: function() {
    return {
      yeet: 'fish'
    };
  }
};

// Vue.use(Share);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
