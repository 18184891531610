import Vue from 'vue';
import Vuex from 'vuex';
import http from '../plugins/http';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    circle: {},
    user: {
      circles: []
    },
    loggedIn: false,
    loaderModal: null,
    toast: {
      show: false,
      message: '',
      style: '',
      actionText: '',
      actionLink: '',
      withAction: false
    },
    authPrompt: {
      show: false,
      message: '',
      route: ''
    },
    sidebar: {
      open: false,
      type: 'default'
    },
    token: '',
    search: {
      query: '',
      results: []
    },
    newPosts: false,
    feedPersisted: null,
    currentFeed: {},
    newCommentReply: false,
    comment: {},
    newCommentUpdate: false
  },
  getters: {
    circle: state => {
      return state.circle;
    },
    user: state => {
      if (state.user.username) {
        return state.user;
      }

      return JSON.parse(window.localStorage.getItem('bubbl_user'));
    },
    token: state => {
      if (state.token) {
        return state.token;
      }

      return window.localStorage.getItem('bubbl_ut');
    },
    belongsToCircle: function(state) {
      return function(slug) {
        if (state.token && state.user.circles) {
          return state.user.circles.some(c => c.slug == slug);
        }

        return false;
      };
    },

    findCircle: function(state) {
      return function(slug) {
        if (state.token && state.user.circles) {
          return state.user.circles.find(c => c.slug == slug);
        }
        return { name: 'N/A' };
      };
    },
    toast: state => {
      return state.toast;
    },
    auth_prompt: state => {
      return state.authPrompt;
    },
    sidebar: state => {
      return state.sidebar;
    },
    toast_status: state => {
      return state.toast.show;
    },
    // Comments //
    comment: state => {
      return state.comment;
    },
    newCommentReply: state => {
      return state.newCommentReply;
    },
    newCommentUpdate: state => {
      return state.newCommentUpdate;
    },
    // Comments
    loggedIn: state => {
      if (window.localStorage.getItem('bubbl_ut')) {
        state.loggedIn = true;
      } else {
        state.loggedIn = false;
      }

      return state.loggedIn;
    },
    search: state => {
      return state.search;
    },
    loaderModal: state => {
      return state.loaderModal;
    },
    currentFeed: state => {
      if (state.currentFeed) {
        return state.currentFeed;
      }
      return false;
    }
  },
  mutations: {
    SET_CIRCLE: (state, payload) => {
      state.circle = payload;
    },
    SET_USER: (state, payload) => {
      state.user = payload;
    },
    SET_FEED: (state, payload) => {
      state.feed = payload;
    },
    TOGGLE_LOADER: state => {
      state.loaderModal = !state.loaderModal;
    },
    SET_TOKEN: (state, payload) => {
      state.token = payload;
      window.localStorage.setItem('bubbl_ut', payload);
    },
    SHOW_TOAST: (
      state,
      { message, style, actionText, actionLink, withAction }
    ) => {
      state.toast.show = true;
      state.toast.message = message;
      state.toast.style = style;
      state.toast.actionText = actionText;
      state.toast.actionLink = actionLink;
      state.toast.withAction = withAction;
    },
    SHOW_AUTH_PROMPT: (state, { message }) => {
      state.authPrompt.show = true;
      state.authPrompt.message = message;
    },
    HIDE_TOAST: state => {
      state.toast.show = false;
      state.toast.message = '';
      state.toast.style = '';
      state.toast.actionText = '';
      state.toast.actionLink = '';
      state.toast.withAction = false;
    },
    OPEN_SIDEBAR: state => {
      state.sidebar.open = true;
    },
    PERSIST_SEARCH: (state, { query, results }) => {
      state.search.query = query;
      state.search.results = results;
    },
    CLOSE_SIDEBAR: state => {
      state.sidebar.open = false;
    },
    // Comments //
    NEW_COMMENT_REPLY: (state, comment) => {
      state.newCommentReply = true;
      state.comment = comment;
    },
    NEW_COMMENT_UPDATE: (state, comment) => {
      state.newCommentUpdate = true;
      state.comment = comment;
    },
    TOGGLE_COMMENT_OPTION: (state, option) => {
      state[option] = false;
    },
    // Comments //
    HIDE_AUTH_PROMPT: state => {
      state.authPrompt.show = false;
      state.authPrompt.message = '';
    },
    TOGGLE_LOGGEDIN: state => {
      state.loggedIn = true;
    },
    TOGGLE_LOGGEDOUT: state => {
      state.loggedIn = false;
    },
    TOGGLE_NEW_POSTS: state => {
      state.newPosts = !state.newPosts;
    },
    LEAVE_CIRCLE: (state, slug) => {
      const circle = state.user.circles.findIndex(c => {
        return c.slug === slug;
      });

      if (circle != -1) {
        state.user.circles.splice(circle, 1);
      }
    },

    PERSIST_FEED: (state, persisted) => {
      state.feedPersisted = persisted;
    },

    JOIN_CIRCLE: (state, circle) => {
      state.user.circles.push(circle);
    },
    FETCH_TOKEN: state => {
      if (window.localStorage.getItem('bubbl_ut')) {
        state.token = window.localStorage.getItem('bubbl_ut');
      }
    },
    LOGOUT: state => {
      state.token = '';
      state.user = {
        circles: []
      };
      state.sidebar.open = false;
      state.loggedIn = false;
      window.localStorage.removeItem('bubbl_ut');
      window.localStorage.removeItem('bubbl_user');
    }
  },
  actions: {
    SET_CIRCLE: ({ commit }, payload) => {
      commit('SET_CIRCLE', payload);
    },
    SET_USER: ({ commit }, payload) => {
      commit('SET_USER', payload);
    },
    SET_TOKEN: ({ commit }, payload) => {
      commit('SET_TOKEN', payload);
    },
    LEAVE_CIRCLE: ({ commit }, payload) => {
      commit('LEAVE_CIRCLE', payload);
    },
    JOIN_CIRCLE: ({ commit }, payload) => {
      commit('JOIN_CIRCLE', payload);
    },
    TOGGLE_LOGGEDIN: ({ commit }) => {
      commit('TOGGLE_LOGGEDIN');
    },
    OFF_NEW_POSTS: ({ commit }) => {
      commit('OFF_NEW_POSTS');
    },
    TOGGLE_LOADER: ({ commit }) => {
      commit('TOGGLE_LOADER');
    },
    TOGGLE_NEW_POSTS: ({ commit }) => {
      commit('TOGGLE_NEW_POSTS');
    },
    SHOW_TOAST: ({ commit }, payload) => {
      commit('SHOW_TOAST', payload);
    },
    HIDE_TOAST: ({ commit }) => {
      commit('HIDE_TOAST');
    },
    SHOW_AUTH_PROMPT: ({ commit }, payload) => {
      commit('SHOW_AUTH_PROMPT', payload);
    },
    PERSIST_SEARCH: ({ commit }, payload) => {
      commit('PERSIST_SEARCH', payload);
    },
    HIDE_AUTH_PROMPT: ({ commit }) => {
      commit('HIDE_AUTH_PROMPT');
    },
    OPEN_SIDEBAR: ({ commit }) => {
      commit('OPEN_SIDEBAR');
    },
    // Comments //
    NEW_COMMENT_REPLY: ({ commit }, comment) => {
      commit('NEW_COMMENT_REPLY', comment);
    },
    NEW_COMMENT_UPDATE: ({ commit }, comment) => {
      commit('NEW_COMMENT_UPDATE', comment);
    },
    TOGGLE_COMMENT_OPTION: ({ commit }, option) => {
      commit('TOGGLE_COMMENT_OPTION', option);
    },
    // Comments //
    CLOSE_SIDEBAR: ({ commit }) => {
      commit('CLOSE_SIDEBAR');
    },
    LOGOUT: ({ commit }) => {
      commit('LOGOUT');
    },
    FETCH_TOKEN: ({ commit, state }) => {
      if (state.token == '') {
        commit('FETCH_TOKEN');
      }
    },
    FETCH_USER: ({ commit, state }) => {
      const headers = {
        Authorization: `token ${state.token ||
          window.localStorage.getItem('bubbl_ut')}`
      };

      if (!window.localStorage.getItem('bubbl_ut')) {
        console.log('No token. User is not logged in');
        commit('TOGGLE_LOGGEDOUT');
        return false;
      }

      http
        .get('/v1/current-user', { headers })
        .then(response => {
          let user = {};
          if (response.data.success) {
            user = {
              ...response.data.data,
              id: response.data.data.user_id
            };
            commit('SET_USER', user);
            window.localStorage.setItem('bubbl_user', JSON.stringify(user));
          }
        })
        .catch(error => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          }
          commit('SHOW_TOAST', {
            message: "Couldn't fetch user :/",
            style: 'error'
          });
          // TODO: Maybe show a toast
        })
        .finally(() => {
          // if successfull... set as loggedIn
          let localUser = window.localStorage.getItem('bubbl_user');
          // this will be defined if network fetch was succsessful...
          if (localUser) {
            commit('TOGGLE_LOGGEDIN');
          }
        });
    },
    CLEAR_PARTITIONS: () => {
      clearPartitions();
    },
    // eslint-disable-next-line no-unused-vars
    PERSIST_FEED: ({ commit }, payload) => {
      if (payload) {
        commit('PERSIST_FEED', true);

        let {
          posts,
          page,
          partitions_size,
          next_page,
          previous_page
        } = payload;

        try {
          savePartitions(posts, page, next_page, previous_page);
        } catch (err) {
          console.log('Error performing partitioning');
        }
        window.localStorage.setItem(
          'bubbl_user_feed_last_partition_saved',
          page
        );

        partitionsArray(page);

        window.localStorage.setItem('current_partition', page);

        window.localStorage.setItem(
          'bubbl_user_feed_partition_size',
          partitions_size
        );

        if (page == 1) {
          window.localStorage.setItem(
            `bubbl_user_feed_first_saved_time`,
            Date.now()
          );
        }

        window.localStorage.setItem(
          `bubbl_user_feed_last_saved_time`,
          Date.now()
        );
      } else {
        commit('PERSIST_FEED', false);
      }
    }
  },
  modules: {}
});

/**
 * save feed partitions in localStorage
 */
function savePartitions(posts, page, next_page, previous_page) {
  try {
    // be cutting the first ten
    window.localStorage.setItem(
      `bubbl_user_feed_partition-${page}`,
      JSON.stringify({ posts, page, next_page, previous_page })
    );

    return true;
  } catch (error) {
    return false;
  }
}

function partitionsArray(current_page) {
  let saved_partitions = window.localStorage.getItem(
    'bubbl_user_feed_saved_partitions'
  );

  if (saved_partitions) {
    let saved_partitions_json = JSON.parse(saved_partitions);

    saved_partitions_json.push(current_page);

    window.localStorage.setItem(
      'bubbl_user_feed_saved_partitions',
      JSON.stringify(saved_partitions_json)
    );
  } else {
    window.localStorage.setItem(
      'bubbl_user_feed_saved_partitions',
      JSON.stringify([current_page])
    );
  }
}

export function getPayload(posts, next, page) {
  // this.feed = [...this.feed, ...response.data.results];
  return {
    posts: [...posts],
    page,
    partitions_size: posts.length,
    previous_page: page == 1 ? null : page - 1,
    next_page: next
      ? parseInt(
          next
            .split('page')
            .join('')
            .split('=')[1]
        )
      : null
  };
}

/**
 * remove all the feed partitions in localStorage
 */
export function clearPartitions() {
  let saved_partitions = JSON.parse(
    window.localStorage.getItem('bubbl_user_feed_saved_partitions')
  );

  window.localStorage.removeItem('bubbl_user_feed_saved_partitions');

  window.localStorage.removeItem('bubbl_user_feed_partitions_number');

  window.localStorage.removeItem('bubbl_user_feed_last_saved_time');

  window.localStorage.removeItem('last_viewed_feed_post');

  window.localStorage.removeItem('bubbl_user_feed_first_saved_time');

  window.localStorage.removeItem('bubbl_user_feed_partition_size');

  window.localStorage.removeItem('bubbl_user_feed_last_partition_saved');

  window.localStorage.removeItem('current_partition');

  if (!saved_partitions) {
    return false;
  }

  // Now clear them all!

  try {
    saved_partitions.forEach(page => {
      window.localStorage.removeItem(`bubbl_user_feed_partition-${page}`);
    });
  } catch (error) {
    return false;
  }

  return true;
}

export function parseAsync(data) {
  return new Promise((resolve, reject) => {
    try {
      resolve(JSON.parse(data));
    } catch (error) {
      reject(error);
    }
  });
}
