<template>
  <transition>
    <router-view></router-view>
  </transition>
</template>

<script>
export default {
  name: 'App'
};
</script>
<style lang="scss">
.v-application {
  font-family: 'Open Sans', 'sans-serif' !important;
}

.font-avenir {
  font-family: 'Avenir', 'sans-serif' !important;
}

.font-rubik {
  font-family: 'Rubik', sans-serif !important;
}

// New
.opensans-font {
  font-family: 'Open Sans', sans-serif !important;
}

.font-sen {
  font-family: 'Sen', sans-serif !important;
}

.custom-indigo--text {
  color: #795fdc !important;
}

.special-alert {
  border-radius: 25px;
  border-width: 2px;
  border-style: solid;
  border-color: #795fdc !important;
}

.v-btn {
  font-family: 'Sen' !important;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.post-seperator {
  background-color: #e1e1e1;
  height: 1.5px;
}

.bg-purple {
  background: #795fdc !important;
}

.color-purple {
  color: #795fdc !important;
}

.purple-outline {
  border: 1px solid #795fdc !important;
  box-shadow: none !important;
}

.p-0 {
  padding: 0rem !important;
}

.m-0 {
  margin: 0rem !important;
}
</style>
