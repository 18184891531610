// @ts-ignore
import Vue from 'vue';
// @ts-ignore
import VueRouter from 'vue-router';
// @ts-ignore
import VueMeta from 'vue-meta';
import AppView from '../views/user/app-view.vue';
// import Feed from '../views/user/feed.vue';
// import Entry from '../views/auth/entry.vue';
// import AuthHome from '../views/auth/home.vue';
// import Circles from '../views/circles/circles.vue';
// import ViewCircle from '../views/circles/circle/view-circle.vue';
// import CirclesHome from '../views/circles/home.vue';
// import CreateCircle from '../views/circles/create-circle/create-circle.vue';
// import userProfile from '../views/user/profile/profile.vue';
// import ViewPost from '../views/posts/view-post.vue';
// import Find from '../views/find/find.vue';
import NotFoundPage from '../views/not-found.vue';
// import QuickIntro from '../views/others/quick-intro.vue';

Vue.use(VueRouter);
Vue.use(VueMeta, {
  keyName: 'head'
});

const routes = [
  {
    path: '',
    redirect: '',
    // component: () => import('@/views/user/app-view.vue'),
    component: AppView,
    children: [
      {
        path: '',
        component: () => import('@/views/user/feed.vue'),
        name: 'Feed',
        meta: {
          showBottomNav: true
        }
      },
      {
        path: 'circles',
        component: () => import('@/views/circles/circles.vue'),
        name: 'Circles',
        meta: {
          showBottomNav: true
        }
      },
      {
        path: 'c/:circle_slug',
        component: () => import('@/views/circles/home.vue'),
        children: [
          {
            path: '',
            component: () => import('@/views/circles/circle/view-circle.vue'),
            name: 'View Circle',
            meta: {
              showBottomNav: true,
              insideCircle: true
            }
          },
          {
            path: 'p/:post_slug',
            component: () => import('@/views/posts/view-post.vue'),
            name: 'View Post',
            meta: {
              showBottomNav: true,
              insideCircle: true
            }
          }
        ]
      },

      {
        path: 'new/circle',
        component: () =>
          import('@/views/circles/create-circle/create-circle.vue'),
        name: 'Create Circle',
        meta: {
          showBottomNav: false
        }
      },
      {
        path: 'u/:username',
        component: () => import('@/views/user/profile/profile.vue'),
        name: 'User Profile',
        meta: {
          showBottomNav: true
        }
      },
      {
        path: 'find',
        component: () => import('@/views/find/find.vue'),
        name: 'Find',
        meta: {
          showBottomNav: true
        }
      },
      {
        path: 'intro',
        component: () => import('@/views/others/quick-intro.vue'),
        name: 'Intro',
        meta: {
          showBottomNav: false
        }
      }
    ]
  },
  {
    path: '/auth',
    component: () => import('@/views/auth/home.vue'),
    redirect: 'auth/continue',
    children: [
      {
        path: 'continue',
        component: () => import('@/views/auth/entry.vue'),
        name: 'Auth'
      }
    ]
  },
  // {
  //   path: '/games',
  //   component: GamesHome,
  //   children: [
  //     {
  //       path: 'fun',
  //       component: () =>
  //         externalComponent('http://localhost:8200/game/dest/Game.umd.min.js'),
  //       name: 'Fun Game',
  //       props: {
  //         name: 'Chris'
  //       }
  //     },
  //     {
  //       path: 'snake/:id',
  //       component: () =>
  //         externalComponent('http://localhost:8200/game/dest/Snake.umd.min.js'),
  //       name: 'Snake Game',
  //       props: {
  //         name: 'Bubbl'
  //       }
  //     }
  //   ]
  // },
  {
    path: '*',
    component: NotFoundPage,
    name: '404'
  }
];

const scrollBehavior = function(to) {
  if (to.name == 'Feed') {
    let position = { x: 0, y: 5 };
    return new Promise(resolve => {
      // check if any matched route config has meta that requires scrolling to top
      // if (to.matched.some(m => m.meta.scrollToTop)) {
      //   // coords will be used if no selector is provided,
      //   // or if the selector didn't match any element.
      //   position.x = 0
      //   position.y = 0
      // }
      let currentPost = window.localStorage.getItem('last_viewed_feed_post');
      position = { selector: currentPost, offset: { x: 0, y: 80 } };
      // wait for the out transition to complete (if necessary)
      this.app.$root.$once('feed-populated', () => {
        // if the resolved position is falsy or an empty object,
        // will retain current scroll position.

        resolve(position);
      });
    });
  }

  if (to.hash) {
    return {
      selector: to.hash
      // , offset: { x: 0, y: 10 }
    };
  }
};

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior,
  routes
});

/** Now we are allowing unauthenticated users to view feed */

// router.beforeEach((to, from, next) => {
//   const isAuthenticated = window.localStorage.getItem('bubbl_ut');

//   if (to.name !== 'Auth' && !isAuthenticated) next({ name: 'Auth' });
//   else next();
// });

export default router;
