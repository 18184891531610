<template>
  <v-app class="font-avenir">
    <v-app-bar
      v-if="currentRoute == 'Feed' || currentRoute == 'Circles'"
      app
      hide-on-scroll
      dense
      height="46px"
      color="white"
      light
    >
      <v-toolbar-title class="mx-auto d-flex text-center color-purple">
        <img src="https://i.ibb.co/YPgGPLk/logo.png" height="19px" />
      </v-toolbar-title>

      <v-toolbar-items>
        <v-menu v-if="loggedIn" bottom left>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-badge
                top
                overlap
                offset-x="10"
                offset-y="40"
                color="transparent"
              >
                <template v-slot:badge>
                  <v-icon small>
                    {{ badge }}
                  </v-icon>
                </template>
                <v-avatar
                  :title="loggedIn ? 'Logged in' : 'Not logged in'"
                  class="white--text my-2"
                  size="25px"
                  :color="avatarColor"
                >
                  <span
                    style="font-size: large;font-weight: 500;"
                    v-if="loggedIn"
                  >
                    {{ user.username.charAt(0).toUpperCase() }}
                  </span>
                  <v-icon v-else color="dark">
                    {{ mdiAccount }}
                  </v-icon>
                </v-avatar>
              </v-badge>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="viewProfile">
              <v-list-item-title>View Profile</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- Not logged in -->
        <v-avatar
          v-else
          :title="loggedIn ? 'Logged in' : 'Not logged in'"
          class="white--text my-2"
          size="35px"
          @click="viewProfile"
          :color="avatarColor"
        >
          <v-icon color="dark">
            {{ mdiAccount }}
          </v-icon>
        </v-avatar>
      </v-toolbar-items>

      <template v-slot:extension>
        <!-- <v-sheet
          class="text-center px-2 py-1 d-flex justify-center align-center"
          style="width: 100%"
        >
          <span>
            <v-btn to="/intro" text color="primary accent-4"
              >love & light friends✨</v-btn
            >
          </span>
        </v-sheet> -->
        <v-btn
          depressed
          block
          to="/intro?from=feed-top-bar"
          color="deep-purple lighten-5"
          class="py-2 px-3 body-2 special-alert justify-space-between text-capitalize"
        >
          <span class="deep-purple--text text--darken-3 font-sen mx-sm-auto">
            What is Bubbl
          </span>
          <v-icon right color="deep-purple">
            {{ mdiHelp }}
          </v-icon>
        </v-btn>
      </template>
    </v-app-bar>
    <v-navigation-drawer
      v-model="sidebar.open"
      @input.once="sidebarOpened"
      app
      temporary
      overlay-opacity="0.6"
    >
      <template v-slot:prepend>
        <!-- <div class="pa-3 pb-0">
          <img src="/img/logo.png" height="19px" />
        </div> -->
        <v-card
          tile
          outlined
          style="border-top: none;
    border-left: none;
    border-right: none;"
        >
          <v-img
            class="white--text align-end"
            height="35px"
            src="https://i.ibb.co/s9YGcYP/edited-sharon-mccutcheon-TZZw-C-xs-Cl-Y-unsplash.jpg"
            gradient="to bottom, rgba(100, 115, 201, 0), rgba(0,0,0,0.83)"
          >
          </v-img>
          <v-list nav dense class="py-0 px-3 transparent">
            <v-list-item
              two-line
              @click="viewProfile"
              class="px-0 mb-0 transparent"
            >
              <v-list-item-avatar>
                <!-- <img src="https://randomuser.me/api/portraits/men/81.jpg" /> -->
                <v-avatar
                  class="white--text"
                  size="40px"
                  color="purple lighten-1"
                >
                  {{
                    loggedIn && user
                      ? user.username.charAt(0).toUpperCase()
                      : 'N/A'
                  }}
                </v-avatar>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="subtitle-1"
                  >{{ loggedIn ? user.username : 'Boo!' }}
                  <v-icon small>
                    {{ badge }}
                  </v-icon></v-list-item-title
                >
                <v-list-item-subtitle class="caption"
                  >Your Profile</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>

            <v-list-item
              link
              to="/intro?from=sidebar"
              color="primary lighten-5"
              class="my-3 deep-purple lighten-5"
            >
              <v-list-item-title
                class="subtitle-1 deep-purple--text text--darken-3 py-2"
              >
                What is Bubbl
              </v-list-item-title>

              <v-list-item-avatar class="my-1">
                <v-icon
                  color="deep-purple"
                  right
                  style="font-size: 20px"
                  class="ml-2"
                >
                  {{ mdiHelp }}
                </v-icon>
              </v-list-item-avatar>
            </v-list-item>
          </v-list>
        </v-card>
      </template>

      <template v-if="sidebarDataLoading">
        <v-skeleton-loader
          v-for="(n, i) in 4"
          :key="i"
          :loading="sidebarDataLoading"
          width="160px"
          type="list-item"
        ></v-skeleton-loader>
      </template>

      <!-- <v-list v-else subheader class="mt-6">
        <v-subheader
          class="title black--text"
          style="font-size: 15px !important;"
        >
          What's New ✨
        </v-subheader>
        <v-list-item
          v-for="(data, i) in sidebarData.whatsNew"
          :key="i + '-wnew'"
        >
          <v-list-item-title class="body-2">
            {{ data.label }}
          </v-list-item-title>
        </v-list-item>

        <v-subheader
          class="title black--text"
          style="font-size: 15px !important"
        >
          What's Next 💡
        </v-subheader>

        <v-list-item
          v-for="(data, i) in sidebarData.whatsNext"
          :key="i + '-wnxt'"
        >
          <v-list-item-title class="body-2">
            {{ data.label }}
          </v-list-item-title>
        </v-list-item>
      </v-list> -->

      <template v-slot:append>
        <!-- <v-divider></v-divider> -->
        <v-btn text class="float-right" small @click="sidebar.open = false">
          close
          <v-icon small>
            {{ mdiClose }}
          </v-icon>
        </v-btn>
        <div class="d-flex secondary lighten-5 pa-3 subtitle-2">
          <div>
            <span> From Daigon </span>
            <p class="caption ma-0">
              abeg give us feedback :)
            </p>
          </div>

          <v-spacer></v-spacer>
          <v-btn icon href="https://wa.me/2348140760114" target="_blank">
            <v-icon color="green">
              {{ mdiWhatsapp }}
            </v-icon>
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main>
      <div id="main-app" class="container-fluid">
        <router-view></router-view>
      </div>
    </v-main>

    <v-bottom-navigation
      v-if="showBottomNav"
      app
      color="#fff"
      fixed
      active-class="text--primary"
      height="50px"
      grow
      shift
      background-color="#fff"
    >
      <v-btn style="height: 100%;" to="/">
        <span class="nav-label opensans-font">Feed</span>
        <v-icon
          style="font-size: 26px; position: relative; m-0"
          :color="
            currentRoute == 'Feed'
              ? insideCircle
                ? mainColor
                : 'primary'
              : 'secondary lighten-1'
          "
        >
          {{ mdiHomeOutline }}
        </v-icon>
      </v-btn>

      <v-btn class="pl-0" to="/find" style="height: 100%;">
        <span class="nav-label opensans-font">Find</span>
        <v-icon
          :color="
            currentRoute == 'Find'
              ? insideCircle
                ? mainColor
                : 'primary'
              : 'secondary lighten-1'
          "
          >{{ mdiMagnify }}</v-icon
        >
      </v-btn>

      <!-- FAB -->
      <v-fab-transition>
        <v-btn
          fab
          absolute
          :ripple="true"
          active-class="dark"
          top
          @click="newPost()"
          class="our-fab d-flex flex-column align-items-center justify-content-center"
          :style="
            `border-radius: 50% !important;height: 56px !important;width: 56px !important;min-width: 0px !important;position: absolute !important;box-shadow: inherit !important;
            ${fabBgColor}`
          "
        >
          <v-icon
            class="no-transform"
            style="font-size: 29px; position: relative; m-0"
            color="white"
          >
            {{ mdiPlus }}
          </v-icon>
        </v-btn>
      </v-fab-transition>

      <!-- FAB -->

      <v-btn color="dark" class="ml-10" style="height: 100%;" to="/circles">
        <span class="nav-label opensans-font">Circles</span>
        <!-- TODO: use a regular expression for this, to just check if there's 'Circle' in the route name -->
        <v-icon
          :color="
            currentRoute == 'View Post' ||
            currentRoute == 'View Circle' ||
            currentRoute == 'Circles'
              ? insideCircle
                ? mainColor
                : 'primary'
              : 'secondary lighten-1'
          "
          style="font-size: 26px;"
        >
          {{ mdiAccountGroupOutline }}
        </v-icon>
      </v-btn>

      <v-badge
        color="yellow darken-3"
        top
        left
        overlap
        offset-x="19"
        offset-y="16"
        :value="newEvents"
        dot
      >
        <v-btn @click="openSidebar" style="height: 100%;" class="pl-2">
          <v-icon
            :color="
              sidebar.open || currentRoute == 'View Profile'
                ? insideCircle
                  ? mainColor
                  : 'primary'
                : 'secondary lighten-1'
            "
            class="no-transform"
          >
            {{ mdiMenu }}
          </v-icon>
        </v-btn>
      </v-badge>
    </v-bottom-navigation>

    <v-dialog
      v-model="newPostDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <new-post
        v-if="newPostDialog"
        @close-new-post-dialog="newPostDialog = false"
        :mainColor="mainColor"
      ></new-post>
    </v-dialog>

    <!-- <v-dialog
      v-model="loaderModal"
      :hide-overlay="false"
      content-class="elevation-0 mx-auto overflow-hidden"
      persistent
      width="120"
    >
      <v-progress-circular
        size="60"
        indeterminate
        color="secondary darken-3"
      ></v-progress-circular>
    </v-dialog> -->

    <!-- New loading overlay -->
    <v-overlay v-model="loaderModal">
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <!-- New loading overlay -->

    <!-- New posts snackbar -->
    <v-snackbar
      color="transparent"
      class="mt-10"
      v-model="newPosts"
      :timeout="7000"
      @input="returnNewPosts"
      top
    >
      <template v-slot:default>
        <div class="secondary--text text-center">
          <p>
            New Posts please reload :)
          </p>
          <v-btn
            @click="reload"
            rounded
            dark
            class="ml-0"
            color="pink darken-1"
          >
            Reload
          </v-btn>
        </div>
      </template>
    </v-snackbar>
    <!-- New posts snackbar -->

    <v-snackbar :color="toast.style" v-model="toast.show" :timeout="4000" bottom
      >{{ toast.message }}

      <template v-if="toast.withAction">
        <v-spacer></v-spacer>
        <v-btn outlined color="white" @click="goTo(toast.actionLink)">
          {{ toast.actionText }}
        </v-btn>
        <!-- <v-btn icon @click="toast.show = false">
        <v-icon>{{ mdiClose }}</v-icon>
      </v-btn> -->
      </template>
    </v-snackbar>

    <!-- Service Worker updated alert -->
    <v-snackbar
      v-model="snackWithButtons"
      :timeout="timeout"
      bottom
      left
      class="snack"
    >
      {{ snackWithBtnText }}
      <v-spacer> </v-spacer>
      <v-btn dark outlined color="pink lighten-1" @click.native="refreshApp">
        {{ snackBtnText }}
      </v-btn>
      <v-btn icon @click="snackWithButtons = false">
        <v-icon>{{ mdiClose }}</v-icon>
      </v-btn>
    </v-snackbar>

    <!-- Auth prompt -->
    <v-bottom-sheet v-model="authPrompt.show" inset>
      <v-card tile @click="goToAuth">
        <v-img
          class="white--text align-end"
          height="200px"
          src="https://i.ibb.co/s9YGcYP/edited-sharon-mccutcheon-TZZw-C-xs-Cl-Y-unsplash.jpg"
          gradient="to bottom, rgba(100, 115, 201, 0), rgba(0,0,0,0.83)"
        >
          <v-card-text class="text-center">
            <v-row>
              <v-col cols="12">
                <span
                  class="text--black font-weight-bold"
                  style="font-size: 18px"
                  >Login to continue</span
                >
              </v-col>
              <v-col cols="12">
                <v-btn class="amber font-weight-bold" @click="goToAuth">
                  Login
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-img>
      </v-card>
    </v-bottom-sheet>
  </v-app>
</template>
<script>
// import NewPost from '@/components/new-post/new-post.vue';
import { clearPartitions } from '@/store';
import {
  mdiAccount,
  mdiAccountGroupOutline,
  mdiHelp,
  mdiClose,
  mdiHomeOutline,
  mdiMenu,
  mdiMagnify,
  mdiPlus,
  mdiWhatsapp
} from '@mdi/js';
export default {
  name: 'AppView',
  components: {
    NewPost: () => import('@/components/new-post/new-post.vue')
  },
  data: () => ({
    // Current route //
    currentRoute: null,
    // -- icons -- //
    mdiAccount,
    mdiAccountGroupOutline,
    mdiHelp,
    mdiClose,
    mdiHomeOutline,
    mdiMenu,
    mdiMagnify,
    mdiPlus,
    mdiWhatsapp,
    // ----------- OPTIMISE LATER -- move the imports to another file //
    // ---- //
    feed: false,
    circle: false,
    circleBarHeight: '105',
    newPosts: false,
    newPostDialog: false,
    online: true,
    // service worker sturves
    refreshing: false,
    registration: null,
    // -------- //
    newEvents: null,

    showAuthPrompt: false,
    // snackBar sturves
    snackBtnText: '',
    snackWithBtnText: '',
    snackWithButtons: false,
    // ------- //
    sidebarData: {},
    sidebarDataLoading: false,
    sidebarDataError: false,
    timeout: -1,
    // --- bottomNav --- //
    showBottomNav: true,
    insideCircle: false,
    hideFAB: false
  }),
  computed: {
    user: function() {
      return this.$store.getters.user;
    },
    loaderModal: function() {
      return this.$store.getters.loaderModal;
    },
    toast: function() {
      return this.$store.getters.toast;
    },
    sidebar: function() {
      return this.$store.getters.sidebar;
    },
    authPrompt: function() {
      return this.$store.getters.auth_prompt;
    },
    loggedIn: function() {
      return this.$store.getters.loggedIn;
    },
    badge: function() {
      let icon = '$baby-face';

      if (!this.user) {
        return icon;
      }

      if (this.user.badges) {
        switch (this.user.badges[0].badge.name.trim().toLowerCase()) {
          case 'rookie':
            icon = '$baby-face';
            break;
          case 'regular':
            icon = '$alien';
            break;
          case 'maestro':
            icon = '$smirking-cat';
            break;
          default:
            icon = '$baby-face';
            break;
        }
      }
      return icon;
    },
    mainColor: function() {
      if (this.insideCircle && this.$store.state.circle.styles) {
        return JSON.parse(this.$store.state.circle.styles).mainColor;
      }

      return 'primary';
    },
    avatarColor: function() {
      return this.loggedIn ? 'green accent-3' : 'grey lighten-2';
    },
    fabBgColor: function() {
      if (this.insideCircle && this.$store.state.circle.styles) {
        const mainColor = JSON.parse(this.$store.state.circle.styles).mainColor;

        return `background: ${mainColor};
background: linear-gradient(3deg, ${mainColor} 0%, ${mainColor} 100%);`;
      }

      return `background: rgb(131,58,180);
background: linear-gradient(3deg, rgba(131,58,180,1) 0%, rgba(121,95,220,1) 100%);`;
    }
  },
  watch: {
    '$store.state.newPosts': function(newposts) {
      if (newposts) {
        console.log('new posts');
        this.newPosts = true;
        return;
      }
    },
    online: function(online, offline) {
      if (!online && offline) {
        this.$store.dispatch('SHOW_TOAST', {
          message: 'Connection lost :/',
          style: 'warning'
        });
      } else if (online && !offline) {
        this.$store.dispatch('SHOW_TOAST', {
          message: 'Connection restored :)',
          style: 'success'
        });
      }
    },
    newEvents: function(newvalue) {
      // this.newEvents = newvalue;
      console.log(newvalue);
      window.localStorage.setItem('new-events', JSON.stringify(newvalue));
    },
    $route(to) {
      this.currentRoute = to.name;
    }
  },
  methods: {
    back() {
      this.$router.back();
    },
    goToIntro() {
      if (this.$gtag) {
        this.$gtag.event('visited_intro_from_sidebar', {
          event_label: 'Intro'
        });
      }

      this.$router.push({ name: 'Intro' });
    },
    returnNewPosts(off) {
      if (!off) {
        this.$store.dispatch('TOGGLE_NEW_POSTS');
      }
    },
    goToAuth() {
      // Set current route in localStorage as 'returnTo'
      window.localStorage.setItem('bubbl_returnTo', this.$route.path);
      this.$store.dispatch('HIDE_AUTH_PROMPT');
      this.$router.push({ name: 'Auth' });
    },
    logout() {
      this.$store.dispatch('LOGOUT');
      this.$nextTick(() => {
        this.$router.push({ name: 'Feed' });
      });
    },
    goTo(link) {
      this.$router.push(link);
    },
    reload() {
      window.location.reload();
    },
    newPost() {
      if (!this.loggedIn) {
        this.$store.dispatch('SHOW_AUTH_PROMPT', {
          message: 'Yo!'
        });

        return;
      } else {
        this.newPostDialog = true;
      }
    },
    sidebarOpened(value) {
      if (value) {
        if (value == true) {
          this.$store.dispatch('OPEN_SIDEBAR');
          // this.getSidebarData();
        }
      }
    },
    openSidebar() {
      if (!this.loggedIn) {
        this.$store.dispatch('SHOW_AUTH_PROMPT', {
          message: 'Yo!'
        });

        return;
      }

      this.$store.dispatch('OPEN_SIDEBAR');
      this.newEvents = false;
    },
    viewProfile() {
      if (!this.loggedIn) {
        this.$store.dispatch('SHOW_AUTH_PROMPT', {
          message: 'Yo!'
        });

        return;
      }

      this.$router.push(`/u/${this.user ? this.user.username : 'none'}`);
    },
    updateOnlineStatus() {
      this.online = window.navigator.onLine;
    },
    showRefreshUI(e) {
      // Display a snackbar inviting the user to refresh/reload the app due
      // to an app update being available.
      // The new service worker is installed, but not yet active.
      // Store the ServiceWorkerRegistration instance for later use.
      this.registration = e.detail;
      this.snackBtnText = 'Refresh';
      this.snackWithBtnText = 'New version available!';
      this.snackWithButtons = true;
    },
    refreshApp() {
      this.snackWithButtons = false;
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) {
        return;
      }
      this.registration.waiting.postMessage({ action: 'skipWaiting' });
    }
  },
  created() {
    // Listen for swUpdated event and display refresh snackbar as required.
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    navigator.serviceWorker.addEventListener('controllerchange', () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  },
  mounted() {
    this.$store.dispatch('FETCH_TOKEN');
    this.online = window.navigator.onLine;
    this.$store.dispatch('FETCH_USER');

    this.currentRoute = this.$route.name;

    this.showBottomNav = this.$route.meta.showBottomNav;
    this.insideCircle = this.$route.meta.insideCircle || false;

    // find event marker

    if (!window.localStorage.getItem('new-events')) {
      this.newEvents = false;
      window.localStorage.setItem('new-events', JSON.stringify(false));
    } else {
      this.newEvents = JSON.parse(window.localStorage.getItem('new-events'));
    }

    window.onbeforeunload = function() {
      if (navigator.onLine) {
        // Only clear the localstorage is you are online...
        // window.localStorage.removeItem('bubbl_user_feed');
        console.log('reloading...');
        clearPartitions();
      }
    };

    window.addEventListener('online', this.updateOnlineStatus);
    window.addEventListener('offline', this.updateOnlineStatus);
  },
  beforeDestroy() {
    window.removeEventListener('online', this.updateOnlineStatus);
    window.removeEventListener('offline', this.updateOnlineStatus);
  },
  beforeRouteUpdate(to, from, next) {
    this.showBottomNav = to.meta.showBottomNav;
    this.insideCircle = to.meta.insideCircle || false;
    this.hideFAB = (to.meta.insideCircle && to.hash == '#edit') || false;

    next();
  },
  head: {
    titleTemplate: '%s - Bubbl',
    meta: [
      { name: 'title', content: 'Bubbl' },
      {
        name: 'description',
        content: 'The best content from your local circle is on Bubbl'
      },
      { name: 'keywords', content: 'Bubbl, social, content' },
      { name: 'language', content: 'English' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: 'https://mybubbl.me' },
      { property: 'og:title', content: 'Bubbl' },
      {
        property: 'og:description',
        content: 'The best content from your local circle is on Bubbl'
      },
      { property: 'og:image', content: 'https://i.ibb.co/YPgGPLk/logo.png' },
      { itemprop: 'name', content: 'Bubbl' },
      {
        itemprop: 'description',
        content: 'The best content from your local circle is on Bubbl'
      },
      { itemprop: 'image', content: 'https://i.ibb.co/YPgGPLk/logo.png' },
      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:url', content: 'https://mybubbl.me' },
      { property: 'twitter:title', content: 'Bubbl' },
      {
        property: 'twitter:description',
        content: 'The best content from your local circle is on Bubbl'
      },
      {
        property: 'twitter:image',
        content: 'https://i.ibb.co/YPgGPLk/logo.png'
      }
    ]
  }
};
</script>
<style scoped>
.v-btn--fab.v-size--default.v-btn--absolute.v-btn--bottom {
  bottom: 17px !important;
}

.v-snack >>> .v-snack__wrapper {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2),
    0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12) !important;
}

.v-snack >>> .v-snack__content {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.empty-folder {
  position: absolute;
  left: 41.87%;
  right: 41.87%;
  width: 84px;
  top: 15.43%;
  bottom: 54.26%;
}

.no-transform {
  -webkit-transform: none !important;
  -moz-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
}

.nav-label {
  font-family: 'Sen';
  font-weight: 500;
}
</style>
